exports.components = {
  "component---src-layout-news-js": () => import("./../../../src/layout/news.js" /* webpackChunkName: "component---src-layout-news-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-cctv-firmware-info-js": () => import("./../../../src/pages/cctvFirmwareInfo.js" /* webpackChunkName: "component---src-pages-cctv-firmware-info-js" */),
  "component---src-pages-certification-js": () => import("./../../../src/pages/certification.js" /* webpackChunkName: "component---src-pages-certification-js" */),
  "component---src-pages-commercial-sw-js": () => import("./../../../src/pages/commercialSW.js" /* webpackChunkName: "component---src-pages-commercial-sw-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mas-js": () => import("./../../../src/pages/mas.js" /* webpackChunkName: "component---src-pages-mas-js" */),
  "component---src-pages-partner-and-client-js": () => import("./../../../src/pages/partnerAndClient.js" /* webpackChunkName: "component---src-pages-partner-and-client-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-single-news-js": () => import("./../../../src/pages/singleNews.js" /* webpackChunkName: "component---src-pages-single-news-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */)
}

